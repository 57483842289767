import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

const LazyImage = (props) => {
  const [inView, setInView] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const imageRef = useRef();

  function onIntersection(entries, opts) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setInView(true);
      }
    });
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {
      root: null,
      threshold: 0.25,
    });

    if (imageRef?.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const onImageLoaded = () => setLoaded(true);

  useEffect(() => {
    const currentImageRef = imageRef.current;
    if (currentImageRef) {
      currentImageRef.addEventListener("load", onImageLoaded);
      return () => currentImageRef.removeEventListener("load", onImageLoaded);
    }
  }, []);

  const imageProps = {
    className: clsx(
      "tw-w-full tw-object-cover tw-duration-700",
      loaded && "w-is-loaded",
      props.innerClassName,
    ),
    src: inView ? props.src : null,
    ref: imageRef,
  };

  return (
    <div
      className={clsx(
        "tw-bg-gray-100",
        "tw-bg-center tw-bg-no-repeat tw-bg-[length:20%_auto] tw-bg-[url('./images/placeholder.svg')]",
        props.outerClassName,
      )}
    >
      <img {...imageProps} alt={props.alt || ""} />
    </div>
  );
};

export default LazyImage;
